import React, { useState } from 'react'
import Link from 'next/link'
import { Button } from '@mui/material'
import useStyles from './ItemLinkButton.styles'

const ItemLinkButton = ({ item, setSelectedSubItem }) => {
  const { classes } = useStyles()
  const pathname = window.location.pathname.substring(1)
  const [selectedMenu, setSelectedMenu] = useState()

  const handleMenuItemClick = (value) => {
    setSelectedMenu(value)
    setSelectedSubItem(null)
  }

  return (
    <Link href={item.internal.slug ? `/${item.internal.slug}` : item.external} style={{ height: 'max-content' }}>
      <Button
        variant='text'
        component='span'
        className={selectedMenu == pathname ? classes.buttonSelected : classes.button}
        onClick={() => handleMenuItemClick(item.internal.slug)}
      >
        {item.label}
      </Button>
    </Link>
  )
}

export default ItemLinkButton
