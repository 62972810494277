import Link from 'next/link'
import Image from 'next/image'
import { Grid, Divider, Typography, Box, List, ListItem } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import useStyles from './Footer.styled'
import urlFor from '@/lib/urlFor'

export default function Footer({ columns, socialMediaList }) {
  const { classes } = useStyles()

  return (
    <Box component='footer' width='100%' alignSelf='flex-end' className={classes.footer}>
      <Box className={classes.footerContainer}>
        <Grid container direction='row' justifyContent='center'>
          {columns?.map((col) => {
            return (
              <Grid key={col._key} item md={3} xs={6}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <ListItem>
                    <Typography variant='h4' className={classes.headerText}>
                      {col.title}
                    </Typography>
                  </ListItem>

                  {col.linkList.map((link) => (
                    <ListItem key={link._key}>
                      <Link
                        href={link.url}
                        replace
                        target='_blank'
                        color='#6F6F70'
                        style={{ textDecoration: 'none' }}
                        locale=''
                      >
                        <Typography className={classes.text}>{link.label}</Typography>
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )
          })}
        </Grid>
        <Divider />
        <Grid container className={classes.containerCopy} justifyContent='space-between'>
          <Grid>
            <Typography className={classes.text}>
              <FormattedMessage id='selfOnboardingPage.landingPage.footer.copyright' />
            </Typography>
          </Grid>

          <Grid display='grid' gridAutoFlow='column' gap='10px'>
            {socialMediaList?.map((socialMedia) => {
              const iconUrl = urlFor(socialMedia.icon)

              return (
                <Link key={socialMedia._key} href={`${socialMedia.url}`}>
                  <Image src={iconUrl} alt={socialMedia.name} width={20} height={20} />
                </Link>
              )
            })}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
