import {
  ARGENTINIAN_FLAG_IMG,
  BRAZILIAN_FLAG_IMG,
  CHILEAN_FLAG_IMG,
  COLOMBIAN_FLAG_IMG,
  COSTARICAN_FLAG_IMG,
  ECUADORIAN_FLAG_IMG,
  MEXICAN_FLAG_IMG,
  PERUVIAN_FLAG_IMG,
  URUGUAYAN_FLAG_IMG
} from './Images'

export const CountryCodeFlag = {
  AR: { code: '+54', flag: ARGENTINIAN_FLAG_IMG, name: 'Argentina' },
  BR: { code: '+55', flag: BRAZILIAN_FLAG_IMG, name: 'Brasil' },
  CL: { code: '+56', flag: CHILEAN_FLAG_IMG, name: 'Chile' },
  CO: { code: '+57', flag: COLOMBIAN_FLAG_IMG, name: 'Colombia' },
  CR: { code: '+506', flag: COSTARICAN_FLAG_IMG, name: 'Costa Rica' },
  EC: { code: '+593', flag: ECUADORIAN_FLAG_IMG, name: 'Ecuador' },
  MX: { code: '+52', flag: MEXICAN_FLAG_IMG, name: 'México' },
  PE: { code: '+51', flag: PERUVIAN_FLAG_IMG, name: 'Perú' },
  UY: { code: '+598', flag: URUGUAYAN_FLAG_IMG, name: 'Uruguay' }
}
