import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  button: {
    color: '#000000',
    '&:hover': {
      backgroundColor: 'rgba(69, 137, 255, 0.04)',
      color: theme.palette.primary.main
    }
  },
  buttonSelected: {
    backgroundColor: 'rgba(69, 137, 255, 0.04)',
    color: theme.palette.primary.main
  }
}))
