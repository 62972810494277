const pathUrlS3 = 'https://rappi-uploads-01-prod.s3-us-west-2.amazonaws.com/portal-docs/selfonboard/images/'

export const ARGENTINIAN_FLAG_IMG = `${pathUrlS3}flags/ar.svg`
export const BRAZILIAN_FLAG_IMG = `${pathUrlS3}flags/br.svg`
export const CHILEAN_FLAG_IMG = `${pathUrlS3}flags/cl.svg`
export const COLOMBIAN_FLAG_IMG = `${pathUrlS3}flags/co.svg`
export const COSTARICAN_FLAG_IMG = `${pathUrlS3}flags/cr.svg`
export const ECUADORIAN_FLAG_IMG = `${pathUrlS3}flags/ec.svg`
export const MEXICAN_FLAG_IMG = `${pathUrlS3}flags/mx.svg`
export const PERUVIAN_FLAG_IMG = `${pathUrlS3}flags/pe.svg`
export const URUGUAYAN_FLAG_IMG = `${pathUrlS3}flags/uy.svg`
export const SPAIN_FLAG_IMG = `${pathUrlS3}flags/es.svg`

export const BACKGROUND_LANDING = `${pathUrlS3}landing_2.png`
export const BACKGROUND_LANDING_MOBILE = `${pathUrlS3}Landing_mobile_2.png`
export const STEP_1_LANDING = `${pathUrlS3}step-1-landing.png`
export const STEP_2_LANDING = `${pathUrlS3}step-2-landing.png`
export const STEP_3_LANDING = `${pathUrlS3}step-3-landing.png`
export const STEP_SECTION_LANDING = `${pathUrlS3}step-section-landing.jpg`

export const NO_RESULTS_IMG = `${pathUrlS3}no-results.svg`
export const HERO_IMG = `${pathUrlS3}background-signup.png`
export const EMPTY_STATE_IMG = `${pathUrlS3}empty-state.svg`
export const RAPPI_MUSTACH_IMG = `${pathUrlS3}bigote-rappi.png`
export const GLOW_IMG = `${pathUrlS3}trail_supermercado.png`
export const PLACEHOLDER_IMG = `${pathUrlS3}schedules-placeholder.svg`
export const CONECTIVITY_IMG = `${pathUrlS3}home-splash-conectivity.svg`

export const EMPTY_IMAGE_IMG = `${pathUrlS3}icon-image-empty.svg`
export const PIN_IMG = `${pathUrlS3}pin.svg`
export const PREVIEW_IMG = `${pathUrlS3}toppings-preview.svg`

export const LINE_IMG = `${pathUrlS3}line.png`
export const FOCUS_IMG = `${pathUrlS3}focus.png`
export const MONEY_CASH_IMG = `${pathUrlS3}money.png`
export const STEP1_SCRAPPER_IMG = `${pathUrlS3}step1Scrapper.png`
export const STEP2_SCRAPPER_IMG = `${pathUrlS3}step2Scrapper.png`
export const STEP3_SCRAPPER_IMG = `${pathUrlS3}step3Scrapper.png`
export const CHECK_IMG = `${pathUrlS3}check.png`
export const RAPPI_PRO_BG_IMG = `${pathUrlS3}rappi-pro-bg.svg`

export const TIMER_IMG = `${pathUrlS3}timer-rappi.png`

export const HEAD_HEART_IMG = `${pathUrlS3}head_heart.png`
export const HEAD_OK_IMG = `${pathUrlS3}head_ok.png`
export const STAR_IMG = `${pathUrlS3}asset-star-1.png`

export const SHOPPER_ALPHA_IMG = `${pathUrlS3}shopper-alpha.png`
export const COMPUTER_IMG = `${pathUrlS3}computer.png`
export const HAND_CEL_IMG = `${pathUrlS3}hand-cel.png`
export const MSJ_INFO_IMG = `${pathUrlS3}msj-info.png`
export const SHUTTER_STOCK_IMG = `${pathUrlS3}shutterstock.png`

export const CASHIER_IMG = `${pathUrlS3}cashier.png`
export const BURGER_IMG = `${pathUrlS3}burger.png`
export const DOCUMENTS_IMG = `${pathUrlS3}prop.png`
export const SHOP_IMG = `${pathUrlS3}shop.png`
export const CHRONOMETER_IMG = `${pathUrlS3}chronometer.png`

export const TROPHY_IMG = `${pathUrlS3}outline_trophy.svg`
export const RAPPI_IMG = `${pathUrlS3}outline_rappitendero.svg`
export const MONEY_IMG = `${pathUrlS3}icons_money_circle.svg`
export const PRIME_IMG = `${pathUrlS3}tags_prime.svg`
export const RT_IMG = `${pathUrlS3}prop-rt.png`
export const BAG_IMG = `${pathUrlS3}prop-bag.png`
export const RAPPI_BAG = `${pathUrlS3}rappi-bag.png`
export const DOUBTS_IMG_MOBILE = `${pathUrlS3}doubts-mobile.png`

export const MENU_FREE_IN_PROGRESS_MOBILE = `${pathUrlS3}menu-free-in-progress-mobile.png`
export const MENU_FREE_IN_PROGRESS = `${pathUrlS3}menu-free-in-progress.png`
export const MENU_FREE_COMPLETED_MOBILE = `${pathUrlS3}menu-free-completed-mobile.png`
export const MENU_FREE_COMPLETED = `${pathUrlS3}menu-free-completed.png`
export const MENU_TIP_CATEGORY = `${pathUrlS3}image-tip-category.png`
export const MENU_TIP_CATEGORY_EXAMPLE = `${pathUrlS3}image-tip-example.png`

export const ARGENTINA_BANK_ACCOUNT_CERTIFICATION = `${pathUrlS3}documents/argentina/bank-account-certification.jpg`
export const BRASIL_BANK_ACCOUNT_CERTIFICATION = `${pathUrlS3}documents/brasil/bank-account-certification.jpg`
export const CHILE_BANK_ACCOUNT_CERTIFICATION = `${pathUrlS3}documents/chile/bank-account-certification.jpg`
export const COLOMBIA_BANK_ACCOUNT_CERTIFICATION = `${pathUrlS3}documents/colombia/bank-account-certification.jpg`
export const MEXICO_BANK_ACCOUNT_CERTIFICATION = `${pathUrlS3}documents/mexico/bank-account-certification.jpg`
export const PERU_BANK_ACCOUNT_CERTIFICATION = `${pathUrlS3}documents/peru/bank-account-certification.jpg`

export const ARGENTINA_JURIDIC_SOCIAL_REASON = `${pathUrlS3}documents/argentina/bramotologia.jpg`
export const COLOMBIA_JURIDIC_SOCIAL_REASON = `${pathUrlS3}documents/colombia/camara-comercio.jpg`
export const MEXICO_JURIDIC_SOCIAL_REASON = `${pathUrlS3}documents/mexico/acta-constitutiva.jpg`

export const ARGENTINA_SOCIAL_REASON = `${pathUrlS3}documents/argentina/cuil.jpg`
export const BRASIL_SOCIAL_REASON = `${pathUrlS3}documents/brasil/cnpj.jpg`
export const CHILE_SOCIAL_REASON = `${pathUrlS3}documents/chile/e-rut.jpg`
export const COLOMBIA_SOCIAL_REASON = `${pathUrlS3}documents/colombia/rut.jpg`
export const MEXICO_SOCIAL_REASON = `${pathUrlS3}documents/mexico/rfc.jpg`
export const PERU_SOCIAL_REASON = `${pathUrlS3}documents/peru/ruc.jpg`

export const ARGENTINA_IDENTIFICATION_DOCUMENT = `${pathUrlS3}documents/argentina/identification-document.jpg`
export const BRASIL_IDENTIFICATION_DOCUMENT = `${pathUrlS3}documents/brasil/identification-document.jpg`
export const CHILE_IDENTIFICATION_DOCUMENT = `${pathUrlS3}documents/chile/identification-document.jpg`
export const COLOMBIA_IDENTIFICATION_DOCUMENT = `${pathUrlS3}documents/colombia/identification-document.jpg`
export const MEXICO_IDENTIFICATION_DOCUMENT = `${pathUrlS3}documents/mexico/identification-document.jpg`
export const PERU_IDENTIFICATION_DOCUMENT = `${pathUrlS3}documents/peru/identification-document.jpg`

export const BAG_SELLO_2_IMG = `${pathUrlS3}bag_sello2.png`
export const BAG_SELLO_1_IMG = `${pathUrlS3}bag_sello1.png`
