import { countryCode } from './CountryCode'

export const PLATFORM_VERSION = 'v.1.0.0'
export const VERSION = 'v.1.0.0'
export const GTM_SETTINGS_ID = 'GTM-5KXJLNQ'
export const DEFAULT_COUNTRY = countryCode.COLOMBIA
export const SIGNUP_VERIFICATION = false

export const I18NCONFIG = {
  base: 'es-co',
  languages: [
    { id: 'es-ar', title: 'Español (Argentina)' },
    { id: 'pt-br', title: 'Portugues (Brasil)' },
    { id: 'es-co', title: 'Español (Colombia)' },
    { id: 'es-cl', title: 'Español (Chile)' },
    { id: 'es-cr', title: 'Español (Costa Rica)' },
    { id: 'es-ec', title: 'Español (Ecuador)' },
    { id: 'es-mx', title: 'Español (Mexico)' },
    { id: 'es-pe', title: 'Español (Peru)' },
    { id: 'es-uy', title: 'Español (Uruguay)' }
  ],
  idStructure: 'delimiter',
  referenceBehavior: 'strong',
  fieldNames: {
    lang: '__i18n_lang',
    references: '__i18n_refs',
    baseReference: '__i18n_base'
  }
}
