import * as React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormControl, Select, MenuItem, Grid } from '@mui/material'
import { KeyboardArrowDownRounded } from '@mui/icons-material'

import useStyles from './CountrySelector.styles'
import { CountryCodeFlag } from '@/constants/CountryCodeFlag'
import { DEFAULT_COUNTRY } from '@/constants/Config'
import { LOCALES_BY_COUNTRY } from '@/constants/Locales'

export default function CountrySelector({ hreflangs }) {
  const router = useRouter()
  const { locale, pathname: NextPathname } = router
  const { classes } = useStyles()
  const [countrySelected, setCountrySelected] = React.useState(locale.split('-')[1]?.toUpperCase() || DEFAULT_COUNTRY)

  const countryOptions = Object.entries(CountryCodeFlag).map(([key, value]) => {
    const locale = LOCALES_BY_COUNTRY[key]
    const href = hreflangs?.find((hreflang) => hreflang.locale === locale)?.href
    const pathname = href ? new URL(href)?.pathname : NextPathname
    const { flag, name } = value
    return {
      id: key,
      flag,
      name,
      pathname,
      locale
    }
  })

  const handleCountryChange = (e) => {
    const countrySelected = e.target.value
    setCountrySelected(countrySelected)
  }

  return (
    <FormControl className={classes.countrySelector}>
      <Select
        classes={{ icon: classes.countrySelectorIcon }}
        MenuProps={{
          anchorReference: 'anchorEl',
          classes: { paper: classes.countryDropdown }
        }}
        SelectDisplayProps={{
          style: { padding: '2.5px 0' }
        }}
        variant='outlined'
        size='small'
        fullWidth
        value={countrySelected}
        onChange={handleCountryChange}
        IconComponent={KeyboardArrowDownRounded}
      >
        {countryOptions.map((countryOption) => {
          return (
            <MenuItem key={countryOption.id} value={countryOption.id} sx={{ padding: '0px', width: '100%' }}>
              <Link
                style={{
                  color: 'unset',
                  textDecoration: 'none',
                  display: 'block',
                  padding: '6px 16px',
                  width: '100%'
                }}
                href={countryOption.pathname}
                locale={countryOption.locale}
              >
                <Grid className={classes.countryItem}>
                  <Image
                    width={28}
                    height={20}
                    src={countryOption.flag}
                    alt={countryOption.name}
                    className={classes.flag}
                  />
                  <span style={{ width: 'auto' }}>{countryOption.name}</span>
                </Grid>
              </Link>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
