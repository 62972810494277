import {
  LANDING_ACCOUNT_STATE,
  LANDING_ACTIVITY,
  LANDING_BOWL,
  LANDING_CLOCK,
  LANDING_GRAPH,
  LANDING_PROFILE,
  LANDING_RAPPITENDERO,
  LANDING_THUMB_DOWN
} from './Icons'
import { STEP_1_LANDING, STEP_2_LANDING, STEP_3_LANDING, STEP_SECTION_LANDING } from './Images'

export const OTP_CODE_LENGTH = 8
export const TIME_TO_WAIT_FOR_RESEND = 30
export const SIGNUP_STEP_CONSTANTS = {
  CREATE_ACCOUNT: 0,
  PHONE_VERIFICATION_CODE: 1,
  EMAIL_VERIFICATION_CODE: 2
}

export const LINK_TO_MI_TIENDA = {
  AR: 'https://mitienda.rappi.com.ar',
  BR: 'https://mitienda.rappi.com.br/',
  CL: 'https://mitienda.rappi.cl/',
  CO: 'https://mitienda.rappi.com.co/',
  CR: 'https://mitienda.rappi.co.cr/',
  EC: 'https://mitienda.rappi.com.ec/',
  MX: 'https://mitienda.rappi.com.mx/',
  PE: 'https://mitienda.rappi.com.pe/',
  UY: 'https://mitienda.rappi.com.uy/'
}

export const TERMS_AND_CONDITIONS_LINKS = {
  AR: 'https://legal.rappi.com/argentina/terminos-y-condiciones-rappi-2/',
  BR: 'https://legal.rappi.com/brazil/termos-e-condicoes-de-uso-da-plataforma-rappi/',
  CL: 'https://legal.rappi.com/chile/terminos-y-condiciones-consumidores-rappi/',
  CO: 'https://legal.rappi.com/colombia/terminos-y-condiciones-de-uso-de-plataforma-rappi-2/',
  CR: 'https://legal.rappi.com/costa-rica/terminos-y-condiciones-rappi-3/',
  EC: 'https://legal.rappi.com/ecuador/terminos-y-condiciones-ecuador/',
  MX: 'https://legal.rappi.com/mexico/terminos-y-condiciones-de-uso-de-plataforma-rappi-3/',
  PE: 'https://legal.rappi.com/peru/terminos-y-condiciones-rappi/',
  UY: 'https://legal.rappi.com/uruguay/terminos-y-condiciones-de-uso-de-plataforma-rappi/'
}

export const PRIVACY_POLICY_LINKS = {
  AR: 'https://legal.rappi.com/argentina/politica-de-privacidad-de-rappi/',
  BR: 'https://legal.rappi.com/brazil/aviso-de-privacidade-e-politicas-de-uso-das-informacao-utilizadas-pela-rappi/',
  CL: 'https://legal.rappi.com/chile/politica-de-privacidad/',
  CO: 'https://legal.rappi.com/colombia/politica-de-proteccion-y-tratamiento-de-datos-personales-rappi-s-a-s/',
  CR: 'https://legal.rappi.com/costa-rica/politica-de-privacidad-de-rappi-2/',
  EC: 'https://legal.rappi.com/ecuador/politica-de-privacidad-de-rappi-y-tratamiento-de-datos-persolanes/',
  MX: 'https://legal.rappi.com/mexico/aviso-de-privacidad-usuarios/',
  PE: 'https://legal.rappi.com/peru/politica-de-privacidad-de-rappi-y-tratamiento-de-datos-personales/',
  UY: 'https://legal.rappi.com/uruguay/aviso-de-privacidad/'
}

export const SIGNUP_ERRORS_MSG = {
  INVALID_CODE: 'selfOnboardingPage.landingPage.hero.verificationCodeForm.otp.error',
  ALREADY_EXISTS: 'selfOnboardingPage.landingPage.hero.createAccountForm.field.mail.alreadyExist',
  MULTIPLE_REQUEST: 'landingPage.hero.verificationCodeForm.multiple.request',
  ERROR: 'landingPage.signup.error'
}

export const MESSAGES = {
  ALREADY_EXISTS: 'already exists',
  TO_MANY_REQUEST: 'Too much request'
}

export const SECTIONS_ID = {
  HERO: 'hero-id',
  BENEFITS: 'benefits-id',
  REQUIREMENTS: 'requirements-id',
  STEPS: 'steps-id',
  FAQ: 'faq-id',
  FOOTER: 'footer-id'
}

export const HEADER_MENU_SECTIONS = {
  key: 'header-menu-sections',
  title: 'selfOnboardingPage.landingPage.header.menu.title',
  items: [
    {
      key: 'header-menu-item-1',
      title: 'selfOnboardingPage.landingPage.header.menu.item.benefits',
      to: SECTIONS_ID.BENEFITS
    },
    {
      key: 'header-menu-item-2',
      title: 'selfOnboardingPage.landingPage.header.menu.item.requirements',
      to: SECTIONS_ID.REQUIREMENTS
    },
    {
      key: 'header-menu-item-3',
      title: 'selfOnboardingPage.landingPage.header.menu.item.steps',
      to: SECTIONS_ID.STEPS
    },
    {
      key: 'header-menu-item-4',
      title: 'selfOnboardingPage.landingPage.header.menu.item.faq',
      to: SECTIONS_ID.FAQ
    }
  ]
}

export const BENEFITS_SECTION = {
  key: 'benefits-section',
  title: 'selfOnboardingPage.landingPage.benefits.title',
  items: [
    {
      key: 'benefits-item-1',
      title: 'selfOnboardingPage.landingPage.benefits.item.1.title',
      description: 'selfOnboardingPage.landingPage.benefits.item.1.description'
    },
    {
      key: 'benefits-item-2',
      title: 'selfOnboardingPage.landingPage.benefits.item.2.title',
      description: 'selfOnboardingPage.landingPage.benefits.item.2.description'
    },
    {
      key: 'benefits-item-3',
      title: 'selfOnboardingPage.landingPage.benefits.item.3.title',
      description: 'selfOnboardingPage.landingPage.benefits.item.3.description'
    },
    {
      key: 'benefits-item-4',
      title: 'selfOnboardingPage.landingPage.benefits.item.4.title',
      description: 'selfOnboardingPage.landingPage.benefits.item.4.description'
    }
  ],
  link: 'selfOnboardingPage.landingPage.benefits.link'
}

export const REQUIREMENTS_SECTION = {
  key: 'requirements-section',
  title: 'selfOnboardingPage.landingPage.requirements.title',
  subtitle: 'selfOnboardingPage.landingPage.requirements.subtitle',
  counter: 'selfOnboardingPage.landingPage.requirements.counter',
  countryTitle: 'selfOnboardingPage.landingPage.requirements.country.title',
  menu: {
    key: 'requirements-menu',
    title: 'selfOnboardingPage.landingPage.requirements.menu.title',
    items: [
      { key: 'requirements-menu-item-1', name: 'selfOnboardingPage.landingPage.requirements.menu.item.1' },
      { key: 'requirements-menu-item-2', name: 'selfOnboardingPage.landingPage.requirements.menu.item.2' },
      { key: 'requirements-menu-item-3', name: 'selfOnboardingPage.landingPage.requirements.menu.item.3' },
      { key: 'requirements-menu-item-4', name: 'selfOnboardingPage.landingPage.requirements.menu.item.4' },
      { key: 'requirements-menu-item-5', name: 'selfOnboardingPage.landingPage.requirements.menu.item.5' }
    ]
  },
  logoAndCover: {
    key: 'requirements-logo-and-cover',
    title: 'selfOnboardingPage.landingPage.requirements.logoAndCover.title',
    items: [
      {
        key: 'requirements-logo-and-cover-item-1',
        name: 'selfOnboardingPage.landingPage.requirements.logoAndCover.item.1'
      },
      {
        key: 'requirements-logo-and-cover-item-2',
        name: 'selfOnboardingPage.landingPage.requirements.logoAndCover.item.2'
      }
    ]
  },

  countries: {
    AR: {
      key: 'requirements-section-ar',
      title: 'selfOnboardingPage.landingPage.requirements.countries.AR.title',
      documents: [
        { key: 'requirements-doc-ar-1', name: 'selfOnboardingPage.landingPage.requirements.country.AR.doc.1' },
        { key: 'requirements-doc-ar-2', name: 'selfOnboardingPage.landingPage.requirements.country.AR.doc.2' },
        { key: 'requirements-doc-ar-3', name: 'selfOnboardingPage.landingPage.requirements.country.AR.doc.3' },
        { key: 'requirements-doc-ar-4', name: 'selfOnboardingPage.landingPage.requirements.country.AR.doc.4' },
        { key: 'requirements-doc-ar-5', name: 'selfOnboardingPage.landingPage.requirements.country.AR.doc.5' }
      ]
    },
    BR: {
      key: 'requirements-section-br',
      title: 'selfOnboardingPage.landingPage.requirements.countries.BR.title',
      documents: [
        { key: 'requirements-doc-br-1', name: 'selfOnboardingPage.landingPage.requirements.country.BR.doc.1' },
        { key: 'requirements-doc-br-2', name: 'selfOnboardingPage.landingPage.requirements.country.BR.doc.2' },
        { key: 'requirements-doc-br-3', name: 'selfOnboardingPage.landingPage.requirements.country.BR.doc.3' },
        { key: 'requirements-doc-br-4', name: 'selfOnboardingPage.landingPage.requirements.country.BR.doc.4' },
        { key: 'requirements-doc-br-5', name: 'selfOnboardingPage.landingPage.requirements.country.BR.doc.5' }
      ]
    },
    CL: {
      key: 'requirements-section-cl',
      title: 'selfOnboardingPage.landingPage.requirements.countries.CL.title',
      documents: [
        { key: 'requirements-doc-cl-1', name: 'selfOnboardingPage.landingPage.requirements.country.CL.doc.1' },
        { key: 'requirements-doc-cl-2', name: 'selfOnboardingPage.landingPage.requirements.country.CL.doc.2' },
        { key: 'requirements-doc-cl-3', name: 'selfOnboardingPage.landingPage.requirements.country.CL.doc.3' },
        { key: 'requirements-doc-cl-4', name: 'selfOnboardingPage.landingPage.requirements.country.CL.doc.4' }
      ]
    },
    CO: {
      key: 'requirements-section-co',
      title: 'selfOnboardingPage.landingPage.requirements.countries.CO.title',
      documents: [
        { key: 'requirements-doc-co-1', name: 'selfOnboardingPage.landingPage.requirements.country.CO.doc.1' },
        { key: 'requirements-doc-co-2', name: 'selfOnboardingPage.landingPage.requirements.country.CO.doc.2' },
        { key: 'requirements-doc-co-3', name: 'selfOnboardingPage.landingPage.requirements.country.CO.doc.3' },
        { key: 'requirements-doc-co-4', name: 'selfOnboardingPage.landingPage.requirements.country.CO.doc.4' }
      ]
    },
    CR: {
      key: 'requirements-section-cr',
      title: 'selfOnboardingPage.landingPage.requirements.countries.CR.title',
      documents: [
        { key: 'requirements-doc-cr-1', name: 'selfOnboardingPage.landingPage.requirements.country.CR.doc.1' },
        { key: 'requirements-doc-cr-2', name: 'selfOnboardingPage.landingPage.requirements.country.CR.doc.2' },
        { key: 'requirements-doc-cr-3', name: 'selfOnboardingPage.landingPage.requirements.country.CR.doc.3' }
      ]
    },
    MX: {
      key: 'requirements-section-mx',
      title: 'selfOnboardingPage.landingPage.requirements.countries.MX.title',
      documents: [
        { key: 'requirements-doc-mx-1', name: 'selfOnboardingPage.landingPage.requirements.country.MX.doc.1' },
        { key: 'requirements-doc-mx-2', name: 'selfOnboardingPage.landingPage.requirements.country.MX.doc.2' },
        { key: 'requirements-doc-mx-3', name: 'selfOnboardingPage.landingPage.requirements.country.MX.doc.3' },
        { key: 'requirements-doc-mx-4', name: 'selfOnboardingPage.landingPage.requirements.country.MX.doc.4' },
        { key: 'requirements-doc-mx-5', name: 'selfOnboardingPage.landingPage.requirements.country.MX.doc.5' }
      ]
    },
    PE: {
      key: 'requirements-section-pe',
      title: 'selfOnboardingPage.landingPage.requirements.countries.PE.title',
      documents: [
        { key: 'requirements-doc-pe-1', name: 'selfOnboardingPage.landingPage.requirements.country.PE.doc.1' },
        { key: 'requirements-doc-pe-2', name: 'selfOnboardingPage.landingPage.requirements.country.PE.doc.2' },
        { key: 'requirements-doc-pe-3', name: 'selfOnboardingPage.landingPage.requirements.country.PE.doc.3' }
      ]
    },
    UY: {
      key: 'requirements-section-uy',
      title: 'selfOnboardingPage.landingPage.requirements.countries.UY.title',
      documents: [
        { key: 'requirements-doc-uy-1', name: 'selfOnboardingPage.landingPage.requirements.country.UY.doc.1' },
        { key: 'requirements-doc-uy-2', name: 'selfOnboardingPage.landingPage.requirements.country.UY.doc.2' },
        { key: 'requirements-doc-uy-3', name: 'selfOnboardingPage.landingPage.requirements.country.UY.doc.3' },
        { key: 'requirements-doc-uy-4', name: 'selfOnboardingPage.landingPage.requirements.country.UY.doc.4' },
        { key: 'requirements-doc-uy-5', name: 'selfOnboardingPage.landingPage.requirements.country.UY.doc.5' }
      ]
    },
    EC: {
      key: 'requirements-section-ec',
      title: 'selfOnboardingPage.landingPage.requirements.countries.ec.title',
      documents: [
        { key: 'requirements-doc-ec-1', name: 'selfOnboardingPage.landingPage.requirements.country.EC.doc.1' },
        { key: 'requirements-doc-ec-2', name: 'selfOnboardingPage.landingPage.requirements.country.EC.doc.2' },
        { key: 'requirements-doc-ec-3', name: 'selfOnboardingPage.landingPage.requirements.country.EC.doc.3' }
      ]
    }
  }
}

export const FAQ_SECTION = {
  key: 'faq-section',
  title: 'selfOnboardingPage.landingPage.faq.title',
  questions: [
    {
      key: 'faq-question-1',
      title: 'selfOnboardingPage.landingPage.faq.1.title',
      description: 'selfOnboardingPage.landingPage.faq.1.description'
    },
    {
      key: 'faq-question-2',
      title: 'selfOnboardingPage.landingPage.faq.2.title',
      description: 'selfOnboardingPage.landingPage.faq.2.description'
    },
    {
      key: 'faq-question-3',
      title: 'selfOnboardingPage.landingPage.faq.3.title',
      description: 'selfOnboardingPage.landingPage.faq.3.description'
    },
    {
      key: 'faq-question-4',
      title: 'selfOnboardingPage.landingPage.faq.4.title',
      description: 'selfOnboardingPage.landingPage.faq.4.description'
    },
    {
      key: 'faq-question-5',
      title: 'selfOnboardingPage.landingPage.faq.5.title',
      description: 'selfOnboardingPage.landingPage.faq.5.description'
    },
    {
      key: 'faq-question-6',
      title: 'selfOnboardingPage.landingPage.faq.6.title',
      description: 'selfOnboardingPage.landingPage.faq.6.description'
    },
    {
      key: 'faq-question-7',
      title: 'selfOnboardingPage.landingPage.faq.7.title',
      description: 'selfOnboardingPage.landingPage.faq.7.description'
    },
    {
      key: 'faq-question-8',
      title: 'selfOnboardingPage.landingPage.faq.8.title',
      description: 'selfOnboardingPage.landingPage.faq.8.description'
    },
    {
      key: 'faq-question-9',
      title: 'selfOnboardingPage.landingPage.faq.9.title',
      description: 'selfOnboardingPage.landingPage.faq.9.description'
    },
    {
      key: 'faq-question-10',
      title: 'selfOnboardingPage.landingPage.faq.10.title',
      description: 'selfOnboardingPage.landingPage.faq.10.description'
    },
    {
      key: 'faq-question-11',
      title: 'selfOnboardingPage.landingPage.faq.11.title',
      description: 'selfOnboardingPage.landingPage.faq.11.description'
    }
  ]
}

export const STEPS_SECTION = {
  key: 'steps-section',
  title: 'selfOnboardingPage.landingPage.steps.title',
  subtitle: 'selfOnboardingPage.landingPage.steps.subtitle',
  background: STEP_SECTION_LANDING,
  items: [
    {
      key: 'steps-item-1',
      content: 'selfOnboardingPage.landingPage.steps.1',
      image: STEP_1_LANDING
    },
    {
      key: 'steps-item-2',
      content: 'selfOnboardingPage.landingPage.steps.2',
      image: STEP_2_LANDING
    },
    {
      key: 'steps-item-3',
      content: 'selfOnboardingPage.landingPage.steps.3',
      image: STEP_3_LANDING
    }
  ],
  link: 'selfOnboardingPage.landingPage.steps.link'
}

export const LOGIN_DROPDOWN = {
  key: 'login-dropdown',
  portalPartners: {
    key: 0,
    title: 'selfOnboardingPage.landingPage.header.menu.loginDropdown.portalPartners.title',
    items: [
      {
        key: 'login-dropdown-portal-partners-item-1',
        icon: LANDING_PROFILE,
        label: 'selfOnboardingPage.landingPage.header.menu.loginDropdown.portalPartners.item.1'
      },
      {
        key: 'login-dropdown-portal-partners-item-2',
        icon: LANDING_ACTIVITY,
        label: 'selfOnboardingPage.landingPage.header.menu.loginDropdown.portalPartners.item.2'
      },
      {
        key: 'login-dropdown-portal-partners-item-3',
        icon: LANDING_GRAPH,
        label: 'selfOnboardingPage.landingPage.header.menu.loginDropdown.portalPartners.item.3'
      },
      {
        key: 'login-dropdown-portal-partners-item-4',
        icon: LANDING_ACCOUNT_STATE,
        label: 'selfOnboardingPage.landingPage.header.menu.loginDropdown.portalPartners.item.4'
      },
      {
        key: 'login-dropdown-portal-partners-item-5',
        icon: LANDING_BOWL,
        label: 'selfOnboardingPage.landingPage.header.menu.loginDropdown.portalPartners.item.5'
      },
      {
        key: 'login-dropdown-portal-partners-item-6',
        icon: LANDING_THUMB_DOWN,
        label: 'selfOnboardingPage.landingPage.header.menu.loginDropdown.portalPartners.item.6'
      }
    ]
  },
  rappiAllies: {
    key: 1,
    title: 'selfOnboardingPage.landingPage.header.menu.loginDropdown.rappiAllies.title',
    items: [
      {
        key: 'login-dropdown-rappi-allies-item-1',
        icon: LANDING_RAPPITENDERO,
        label: 'selfOnboardingPage.landingPage.header.menu.loginDropdown.rappiAllies.item.1'
      },
      {
        key: 'login-dropdown-rappi-allies-item-2',
        icon: LANDING_CLOCK,
        label: 'selfOnboardingPage.landingPage.header.menu.loginDropdown.rappiAllies.item.2'
      }
    ]
  }
}

export const SECRET_KEY = 'BRofUlcSKzbFz5Az'
