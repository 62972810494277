import * as React from 'react'
import { Box, Container } from '@mui/material'
import Header from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import Footer from '../footer/Footer'
import { SIGNUP_NAME } from '@/constants/FieldNames'

export default function Layout({ children, hreflangs, menuData, footerData }) {
  const [isSidebarOpen, setSidebarOpen] = React.useState(false)

  const openSidebar = () => setSidebarOpen(true)
  const closeSidebar = () => setSidebarOpen(false)

  return (
    <Container
      disableGutters
      maxWidth='false'
      sx={{ display: 'flex', minHeight: '100vh', flexWrap: 'wrap', height: '100%' }}
    >
      <Header
        hreflangs={hreflangs}
        menuData={menuData}
        openSidebar={openSidebar}
        focusFirstInput={() => {
          document.getElementById(SIGNUP_NAME)?.focus({ preventScroll: true })
        }}
      />
      <Sidebar
        hreflangs={hreflangs}
        menuData={menuData}
        open={isSidebarOpen}
        onCloseHandler={closeSidebar}
        focusFirstInput={() => {
          document.getElementById(SIGNUP_NAME)?.focus({ preventScroll: true })
        }}
      />
      <Box component='main' width='100%' marginTop='64px'>
        {children}
      </Box>
      <Footer {...footerData} />
    </Container>
  )
}
