export const countryCode = {
  ARGENTINA: 'AR',
  BOLIVIA: 'BO',
  BRAZIL: 'BR',
  CHILE: 'CL',
  COLOMBIA: 'CO',
  COSTA_RICA: 'CR',
  DEV: 'Dev',
  ECUADOR: 'EC',
  MEXICO: 'MX',
  PERU: 'PE',
  URUGUAY: 'UY'
}
