import React, { useState } from 'react'
import { ArrowDropDown } from '@mui/icons-material'
import { Menu, MenuItem, Button } from '@mui/material'
import Link from 'next/link'
import useStyles from './SubItemsLink.styles'

const SubItemsLink = ({ item, setSelectedSubItem, selectedSubItem }) => {
  const { classes } = useStyles()
  const [selectedIndex, setSelectedIndex] = useState()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuItemClick = (index, name) => {
    setSelectedIndex(index)
    setSelectedSubItem(name)
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id={`basic-button-${item._key}`}
        variant='text'
        component='span'
        className={selectedIndex && selectedSubItem == item?.label ? classes.buttonSelected : classes.button}
        aria-controls={open ? `basic-menu-${item._key}` : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
      >
        {item?.label}
      </Button>

      <Menu
        id={`basic-menu-${item._key}`}
        open={Boolean(anchorEl) && anchorEl.id === `basic-button-${item._key}`}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `basic-button-${item._key}`
        }}
      >
        {item.subItems.map((subItem, index) => {
          return (
            <MenuItem
              key={subItem._key}
              className={
                index + 1 === selectedIndex && selectedSubItem == item?.label
                  ? classes.menuItemSelected
                  : classes.menuItem
              }
              selected={index + 1 === selectedIndex && selectedSubItem == item?.label}
              onClick={() => handleMenuItemClick(index + 1, item?.label)}
            >
              <Link
                style={{ color: 'unset', textDecoration: 'none' }}
                href={subItem?.internal?.slug ? `/${subItem?.internal?.slug}` : subItem?.external}
              >
                {subItem.label}
              </Link>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default SubItemsLink
