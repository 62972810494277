const pathUrlS3 = 'https://rappi-uploads-01-prod.s3-us-west-2.amazonaws.com/portal-docs/selfonboard/images/icons/'

export const ARROW_ICON = `${pathUrlS3}arrow-small-white.png`
export const ARROW_LEFT_ICON = `${pathUrlS3}arrow-left-thin.svg`
export const ARROW_RIGHT_PURPLE_ICON = `${pathUrlS3}arrow-right-purple.svg`
export const DROP_DOWN_ICON = `${pathUrlS3}arrow_dropdown.svg`
export const DROP_DOWN_DISABLED_ICON = `${pathUrlS3}arrow_dropdown_disabled.svg`

export const EYE_CLOSED_ICON = `${pathUrlS3}eye-closed.png`
export const EYE_OPEN_ICON = `${pathUrlS3}eye-open.svg`
export const SEARCH_ICON = `${pathUrlS3}search.svg`

export const EARTH_ICON = `${pathUrlS3}public.png`
export const MUSTACH_ICON = `${pathUrlS3}mustach.png`
export const FACE_GOOD_ICON = `${pathUrlS3}face_good.svg`
export const MENU_STEP_ICON = `${pathUrlS3}MenuStepsIcon.svg`

export const STAR_ICON = `${pathUrlS3}star.svg`
export const SHOP_ICON = `${pathUrlS3}filled_shop.svg`
export const TAG_ICON = `${pathUrlS3}filled_tag.svg`
export const WAND_ICON = `${pathUrlS3}filled_magic_wand.svg`
export const THUMB_ICON = `${pathUrlS3}filled_thumb_up.svg`

export const ARROW_SMALL_ICON = `${pathUrlS3}arrow_small.svg`
export const ALERT_ORANGE_ICON = `${pathUrlS3}alert-orange.svg`
export const CLOSE_ICON = `${pathUrlS3}close-button.svg`

export const EXCLAMATION_ICON = `${pathUrlS3}ic_exclamation_enable.svg`
export const IDEA_ICON = `${pathUrlS3}idea.svg`
export const CHECKED_ICON = `${pathUrlS3}checked.svg`
export const CHECKED_BOLD_ICON = `${pathUrlS3}checked_bold.svg`
export const CHECKED_OUTLINE_ICON = `${pathUrlS3}check-circle-outline.svg`
export const CHECKED_CIRCLE_ICON = `${pathUrlS3}check-circle.svg`
export const CHECKED_DISABLED_ICON = `${pathUrlS3}checkedDisabled.svg`
export const CLOCK_ICON = `${pathUrlS3}clock.svg`
export const CROWN_ICON = `${pathUrlS3}crown.svg`
export const PHONE_ICON = `${pathUrlS3}phone.svg`
export const PLAY_ICON = `${pathUrlS3}play.svg`
export const PERCENTAGE_ICON = `${pathUrlS3}percentage.svg`
export const HAMBURGUER_ICON = `${pathUrlS3}restaurants.svg`

export const WARNING_BLUE_ICON = `${pathUrlS3}warning-blue-icon.svg`
export const WARNING_GREEN_ICON = `${pathUrlS3}warning-green-icon.svg`
export const WARNING_ORANGE_ICON = `${pathUrlS3}warning-orange-icon.svg`
export const WARNING_RED_ICON = `${pathUrlS3}warning-red-icon.svg`
export const WARNING_ARROW_BLUE_ICON = `${pathUrlS3}warning-arrow-blue-icon.svg`
export const WARNING_ARROW_GREEN_ICON = `${pathUrlS3}warning-arrow-green-icon.svg`
export const WARNING_ARROW_ORANGE_ICON = `${pathUrlS3}warning-arrow-orange-icon.svg`
export const WARNING_ARROW_RED_ICON = `${pathUrlS3}warning-arrow-red-icon.svg`

export const LANDING_ACCOUNT_STATE = `${pathUrlS3}landing-account-state.svg`
export const LANDING_ACTIVITY = `${pathUrlS3}landing-activity.svg`
export const LANDING_BOWL = `${pathUrlS3}landing-bowl.svg`
export const LANDING_CLOCK = `${pathUrlS3}landing-clock.svg`
export const LANDING_GRAPH = `${pathUrlS3}landing-graph.svg`
export const LANDING_PROFILE = `${pathUrlS3}landing-profile.svg`
export const LANDING_RAPPITENDERO = `${pathUrlS3}landing-rappitendero.svg`
export const LANDING_THUMB_DOWN = `${pathUrlS3}landing-thumb_down.svg`
