import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  wrapper: {
    maxWidth: 1280,
    margin: '0 auto'
  },
  appBarRoot: {
    alignItems: 'center',
    color: '#000000',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid rgba(184.0, 180.0, 180.0, 0.2)',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row',
    height: 64,
    padding: '16px 24px',
    [theme.breakpoints.up('md')]: {
      padding: '16px 50px'
    },
    zIndex: theme.zIndex.drawer + 1
  },
  appBarActions: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    alignItems: 'center',
    gap: 6,
    '& a': {
      textDecoration: 'unset'
    },
    [theme.breakpoints.up('lg')]: {
      gap: 10
    }
  },
  appBarLink: {
    color: '#000000',
    fontWeight: 600,
    fontSize: 14,
    padding: '4px 16px',
    cursor: 'pointer',
    textDecoration: 'unset'
  },
  appBarButton: {
    height: 40,
    fontSize: 12,
    padding: '6px 10px',
    [theme.breakpoints.up('lg')]: {
      padding: '6px 16px',
      fontSize: 14,
      minWidth: 120
    }
  },
  divider: {
    width: 2,
    height: '100%',
    backgroundColor: '#EDEDED',
    margin: '0 16px'
  }
}))
