import { client } from './sanity.client'
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(client)

export default function urlFor(source, width, height) {
  if (!source || !source.asset) return ''
  if (width && height) return builder.image(source).auto('format').width(width).height(height).url()
  return builder.image(source).auto('format').fit('fill').url()
}
