import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  sidebarRoot: {
    zIndex: 1202
  },
  sidebarPaper: {
    display: 'grid',
    gridTemplateRows: 'max-content max-content 1fr max-content',
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    maxHeight: '100%',
    margin: 0
  },
  sidebarHeader: {
    height: 85,
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 16px'
  },
  sidebarSubHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    padding: '16px 0',
    margin: '0 16px',
    gap: 16,
    borderTop: '1px solid #E6EBF1',
    borderBottom: '1px solid #E6EBF1'
  },
  linkScroll: {
    width: '100%'
  },
  sidebarSubHeaderButton: {
    width: '100% !important',
    height: 40
  },
  sidebarDialogContentRoot: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
    padding: 0,
    margin: '0 16px'
  },
  sidebarDialogContentTitle: {
    padding: '30px 0 20px',
    textAlign: 'left',
    fontSize: 16
  },
  sidebarDialogcontentList: {
    margin: 0,
    padding: 0
  },
  sidebarDialogContentListItemButton: {
    padding: 0,
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 600,
    textDecoration: 'none',
    color: '#000000',
    padding: '13px 6px'
  },
  sidebarDialogContentListItem: {
    padding: 0,
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 600,
    textDecoration: 'none',
    color: '#000000',
    a: {
      padding: '13px 6px'
    }
  },
  sidebarDialogContentListSubItem: {
    padding: 0,
    textAlign: 'left',
    fontSize: 16,
    textDecoration: 'none',
    color: '#000000',
    a: {
      padding: '13px 6px 13px 24px'
    }
  },
  sidebarDialogActionsRoot: {
    padding: '32px 0',
    margin: '0 16px',
    borderTop: '1px solid #E6EBF1'
  },
  link: {
    fontSize: 'inherit',
    color: 'inherit',
    textDecoration: 'none',
    width: '100%'
  }
}))
