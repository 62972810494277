import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  countrySelector: {
    width: '100%',
    minWidth: 185,
    height: '40px',
    '& *': {
      fontWeight: '400 !important',
      [theme.breakpoints.down('md')]: {
        border: 'none !important',
        outline: 'none !important'
      }
    },
    [theme.breakpoints.up('md')]: {
      border: 'initial',
      width: 'max-content'
    }
  },
  countrySelectorIcon: {
    color: '#000',
    marginRight: 8
  },
  countryDropdown: {
    bottom: 0,
    [theme.breakpoints.up('md')]: {
      top: '50px',
      bottom: 'initial'
    }
  },
  countryItem: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gap: 10
  }
}))
