export const LOCALES_BY_COUNTRY = {
  AR: 'es-ar',
  BR: 'pt-br',
  CL: 'es-cl',
  CO: 'es-co',
  CR: 'es-cr',
  EC: 'es-ec',
  PE: 'es-pe',
  MX: 'es-mx',
  UY: 'es-uy'
}
