import * as React from 'react'
import Link from 'next/link'
import { AppBar, Button, Grid, useMediaQuery } from '@mui/material'
import { MenuRounded } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'

import useStyles from './Header.styles'
import CountrySelector from '../country-selector/CountrySelector'
import RappiIcon from '@/icons/RappiIcon'
import ItemLinkButton from '../item-link-button/ItemLinkButton'
import SubItemsLink from '../sub-items-link/SubItemsLink'
import { useRouter } from 'next/router'

export default function Header({ openSidebar, focusFirstInput, hreflangs, menuData }) {
  const { classes } = useStyles()
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('1280'))
  const { asPath, push } = useRouter()

  const [selectedSubItem, setSelectedSubItem] = React.useState()

  return (
    <AppBar position='fixed' color='primary' className={classes.appBarRoot}>
      <Grid container justifyContent='space-between' alignItems='center' className={classes.wrapper}>
        <div className={classes.appBarActions}>
          {!isDesktop && (
            <MenuRounded
              htmlColor='#6A696E'
              onClick={() => {
                openSidebar()
              }}
            />
          )}
          <Link href='/' style={{ display: 'flex', alignItems: 'center' }}>
            <RappiIcon height={!isDesktop ? '24' : '48'} width={!isDesktop ? '56' : '113'} />
          </Link>
        </div>

        {isDesktop && (
          <nav className={classes.appBarActions}>
            {menuData?.items.map((item) => {
              if (item?.hasSubItems) {
                return (
                  <SubItemsLink
                    key={item._key}
                    item={item}
                    setSelectedSubItem={setSelectedSubItem}
                    selectedSubItem={selectedSubItem}
                  />
                )
              }

              return <ItemLinkButton item={item} key={item._key} setSelectedSubItem={setSelectedSubItem} />
            })}
          </nav>
        )}

        <div className={classes.appBarActions}>
          {isTablet && <CountrySelector hreflangs={hreflangs} />}
          {isTablet && <div className={classes.divider}></div>}
          <Button
            LinkComponent={Link}
            id='loginButtonId'
            variant='outlined'
            href={`${process.env.NEXT_PUBLIC_URL_PARTNERS}/login`}
            className={classes.appBarButton}
          >
            <FormattedMessage id={'selfOnboardingPage.landingPage.header.enter'} />
          </Button>
          <Button
            disableElevation
            variant='contained'
            className={classes.appBarButton}
            onClick={() => {
              if (asPath === '/') {
                focusFirstInput()
                scrollTo({ top: 0 })
                return
              }

              push('/')
            }}
          >
            <FormattedMessage id={'selfOnboardingPage.landingPage.header.signup'} />
          </Button>
        </div>
      </Grid>
    </AppBar>
  )
}
