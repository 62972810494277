import * as React from 'react'
import Link from 'next/link'
import { FormattedMessage } from 'react-intl'
import {
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Drawer,
  List,
  ListItemButton,
  Collapse,
  ListItemIcon
} from '@mui/material'
import { ArrowDropDown, CancelRounded } from '@mui/icons-material'

import useStyles from './Sidebar.styles'
import CountrySelector from '../country-selector/CountrySelector'
import { HEADER_MENU_SECTIONS, SECTIONS_ID } from '@/constants/SignupConstants'

const Sidebar = ({ open, onCloseHandler, focusFirstInput, hreflangs, menuData }) => {
  const { classes } = useStyles()
  const [menuItemOpen, setMenuItemOpen] = React.useState(null)

  return (
    <Drawer
      open={open}
      onClose={onCloseHandler}
      classes={{
        root: classes.sidebarRoot,
        paper: classes.sidebarPaper
      }}
    >
      <div className={classes.sidebarHeader}>
        <svg width='56' height='18' viewBox='0 0 56 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M46.5716 5.83962C49.7127 8.08372 52.3244 7.92413 55.272 5.42072C55.5164 5.21625 55.7556 5.0068 56 4.80235C55.5622 7.16115 54.6153 9.27062 53.2204 11.1906C51.4386 13.6341 49.1018 15.3945 46.1694 16.352C39.312 18.6061 30.6524 15.4693 28 9.54989C25.3476 15.4693 16.688 18.6061 9.83055 16.352C6.89818 15.3945 4.56145 13.6341 2.77963 11.1906C1.38473 9.27062 0.432728 7.16115 0 4.80235C0.239271 5.0068 0.483636 5.21625 0.727998 5.42072C3.67054 7.92413 6.28727 8.08372 9.42327 5.83962C10.864 4.80733 12.1571 3.58056 13.6029 2.55823C14.7229 1.7653 15.9142 0.977387 17.1869 0.488664C19.3964 -0.359108 21.6466 -0.0748602 23.6575 1.11702C25.032 1.92987 26.2793 2.93723 27.552 3.89472C27.7302 4.02938 27.8626 4.09919 28 4.09418C28.1324 4.09919 28.2647 4.02938 28.4429 3.89472C29.7156 2.93723 30.968 1.92987 32.3375 1.11702C34.3585 -0.0748602 36.5985 -0.359108 38.8131 0.488664C40.0909 0.977387 41.272 1.7653 42.392 2.55823C43.8378 3.58056 45.1309 4.80733 46.5716 5.83962Z'
            fill='#FC6347'
          />
        </svg>

        <CancelRounded htmlColor='#6A696E' onClick={onCloseHandler} />
      </div>

      <div className={classes.sidebarSubHeader}>
        <Button
          LinkComponent={Link}
          variant='outlined'
          className={classes.sidebarSubHeaderButton}
          href={`${process.env.NEXT_PUBLIC_URL_PARTNERS}/login`}
        >
          <FormattedMessage id={'selfOnboardingPage.landingPage.header.enter'} />
        </Button>
        <Button
          LinkComponent={Link}
          href={`#${SECTIONS_ID.HERO}`}
          scroll={false}
          disableElevation
          type='button'
          variant='contained'
          onClick={() => {
            onCloseHandler()
            focusFirstInput()
          }}
          className={classes.sidebarSubHeaderButton}
        >
          <FormattedMessage id={'selfOnboardingPage.landingPage.header.signup'} />
        </Button>
      </div>
      <DialogContent classes={{ root: classes.sidebarDialogContentRoot }}>
        <Typography className={classes.sidebarDialogContentTitle}>
          <FormattedMessage id={HEADER_MENU_SECTIONS.title} />
        </Typography>

        <List component='nav'>
          <ul className={classes.sidebarDialogcontentList}>
            {menuData?.items.map((item) => {
              if (item?.hasSubItems) {
                return (
                  <div id={`${item._key}-sidebar`} key={`${item._key}-sidebar`}>
                    <ListItemButton
                      onClick={() => setMenuItemOpen((prev) => (prev === item._key ? null : item._key))}
                      component='li'
                      className={classes.sidebarDialogContentListItemButton}
                    >
                      {item.label}
                      <ListItemIcon>
                        <ArrowDropDown />
                      </ListItemIcon>
                    </ListItemButton>
                    <Collapse in={menuItemOpen === item._key} timeout='auto' unmountOnExit>
                      <List component='div' disablePadding>
                        {item.subItems.map((subItem) => {
                          return (
                            <ListItemButton
                              key={subItem._key}
                              component='li'
                              className={classes.sidebarDialogContentListSubItem}
                            >
                              <Link
                                href={subItem.internal.slug ? `${subItem.internal.slug}` : `${subItem.external}`}
                                onClick={onCloseHandler}
                                className={classes.link}
                              >
                                {subItem.label}
                              </Link>
                            </ListItemButton>
                          )
                        })}
                      </List>
                    </Collapse>
                  </div>
                )
              }

              return (
                <ListItemButton
                  key={item._key}
                  component='li'
                  className={classes.sidebarDialogContentListItem}
                  onClick={onCloseHandler}
                >
                  <Link
                    href={item.internal.slug ? `${item.internal.slug}` : `${item.external}`}
                    className={classes.link}
                  >
                    {item.label}
                  </Link>
                </ListItemButton>
              )
            })}
          </ul>
        </List>
      </DialogContent>
      <DialogActions classes={{ root: classes.sidebarDialogActionsRoot }}>
        <CountrySelector hreflangs={hreflangs} />
      </DialogActions>
    </Drawer>
  )
}

export default Sidebar
