import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  button: {
    color: '#000000',
    '&:hover': {
      backgroundColor: 'rgba(69, 137, 255, 0.04)',
      color: theme.palette.primary.main
    }
  },
  menuItem: {
    padding: '0',
    a: {
      padding: '6px 16px',
      width: '100%'
    },
    '&:hover': {
      backgroundColor: 'rgba(69, 137, 255, 0.04)',
      '& a': {
        color: theme.palette.primary.main
      }
    }
  },
  menuItemSelected: {
    padding: '0px',
    a: {
      padding: '6px 16px',
      width: '100%'
    },
    backgroundColor: 'rgba(69, 137, 255, 0.04)',
    '& a': {
      color: theme.palette.primary.main
    }
  },
  buttonSelected: {
    backgroundColor: 'rgba(69, 137, 255, 0.04)',
    color: theme.palette.primary.main
  }
}))
